/* Conteneur principal */
.gestation-tracker {
  max-width: 800px;
  margin: 2rem auto; /* Centrage horizontal */
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.8); /* Blanc avec opacité de 80% */
  backdrop-filter: blur(10px); /* Effet de flou pour un style moderne */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #f3f3f3;
  font-family: 'Arial', sans-serif;
  animation: none !important; /* Désactivation des animations */
}

/* Désactivation des animations pour tous les enfants */
.gestation-tracker * {
  animation: none !important;
}

/* Titre principal */
.gestation-tracker h2 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #e91e63;
  margin-bottom: 1.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* Message d'état */
.gestation-tracker .message {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: #555;
  margin-bottom: 1.5rem;
}

/* Barre de progression */
.gestation-tracker .progress-bar-container {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: 20px;
  overflow: hidden;
  margin-bottom: 1.5rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-top: 1rem;
}

.gestation-tracker .progress-bar-container::before {
  content: 'Progression: ' attr(data-progress) '%'; /* Afficher le texte et le pourcentage */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.9rem;
  font-weight: bold;
  color: #555;
}

.gestation-tracker .progress-bar-title {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #555;
  margin-bottom: 0.5rem;
}

.gestation-tracker .progress-bar {
  height: 100%;
  background-color: #e91e63;
  transition: width 0.5s ease-in-out;
}

/* Chaton animé */
.gestation-tracker .kitten {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.gestation-tracker .kitten img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Tableau de suivi */
.gestation-tracker table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.gestation-tracker th,
.gestation-tracker td {
  padding: 0.75rem;
  text-align: left;
  border: 1px solid #eaeaea;
}

/* Aligner les titres des colonnes à gauche */
.gestation-tracker th {
  text-align: left !important; /* Forcer l'alignement à gauche pour éviter les styles par défaut du navigateur */
}

.gestation-tracker th {
  background-color: #fce4ec;
  color: #e91e63;
  font-weight: bold;
}

.gestation-tracker tr:nth-child(even) {
  background-color: #f9f9f9;
}

.gestation-tracker tr.highlight {
  background-color: #fff3e0;
  border-left: 4px solid #e91e63;
}

.gestation-tracker td:first-child {
  text-align: center;
  font-weight: bold;
  color: #e91e63;
}

/* Pagination ou autres éléments */
.gestation-tracker .caption {
  text-align: center;
  font-size: 0.9rem;
  color: #888;
  margin-top: 1rem;
}

.current-arrow {
  font-size: 3rem !important; /* Forcer la taille de la flèche */
  font-weight: bold;
  color: #e91e63;
  text-align: center;
}

.current-week {
  font-size: 1.25rem; /* Slightly larger */
  font-weight: bold;
  color: #e91e63;
}

/* Ligne de la semaine en cours */
.gestation-tracker .current-step {
  font-weight: bold;
  background-color: #fff3e0; /* Couleur de fond pour mettre en évidence */
  border-left: 4px solid #e91e63; /* Bordure pour accentuer */
}

/* Flèche pour la semaine en cours */
.gestation-tracker .current-step .arrow {
  font-size: 2.4rem; /* Taille deux fois plus grande */
  color: #e91e63; /* Couleur de la flèche */
  font-weight: bold;
}

/* Style pour le composant GestationTracker */

/* Conteneur principal */
.max-w-4xl {
  background: linear-gradient(to bottom, #fff, #f9f9f9);
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Titre */
h2 {
  font-family: 'Arial', sans-serif;
  color: #e91e63;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* Barre de progression */
.progress-bar-container {
  background: #f3f3f3;
  border-radius: 10px;
  overflow: hidden;
  margin: 20px 0;
  height: 20px;
  position: relative;
  margin-top: 1rem;
}

.progress-bar-title {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #555;
  margin-bottom: 0.5rem;
}

.progress-bar-container::before {
  content: 'Progression: ' attr(data-progress) '%'; /* Afficher le texte et le pourcentage */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.9rem;
  font-weight: bold;
  color: #555;
}

.progress-bar {
  height: 100%;
  transition: width 0.5s ease-in-out;
}

/* Tableau */
table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

thead {
  background-color: #ffe6f0;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #ffe6f0;
  transition: background-color 0.3s;
}

/* Phase actuelle */
.current-week {
  font-weight: bold;
  color: #e91e63;
}

.current-arrow {
  color: #e91e63;
  font-size: 1.2em;
}

/* Image animée */
.animate-bounce {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}